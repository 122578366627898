<!-- 客户用量表-详情 -->
<template>
  <div class="page">
    <!-- 显示详情时 -->
    <div style="height: 100%">
      <Row>
        <Col span="6"><Button class="back" @click="goBack">返回</Button></Col>
      </Row>
      <div class="tabelDiv">
        <Table :columns="listColumns" :data="listData" border>
          <!-- 采购单价 -->
          <template slot-scope="{ row, index }" slot="purchase_unit_price">
            <Input v-model="row.purchase_unit_price" placeholder="请输入" style="width: 120px" type="tel" maxlength="12" clearable @on-change="changePurchasePrice(row.purchase_unit_price, index)" />
          </template>
          <!-- 销售单价 -->
          <template slot-scope="{ row, index }" slot="sale_unit_price">
            <Input v-model="row.sale_unit_price" placeholder="请输入" style="width: 120px" type="tel" maxlength="12" clearable @on-change="changeSalePrice(row.sale_unit_price, index)" />
          </template>
          <!-- 条码 -->
          <template slot-scope="{ row, index }" slot="bar_code">
            <Input placeholder="请输入" size="small" v-model="row.bar_code" @on-change="changeTiaoma(row.bar_code, index)" style="width: 120px" type="tel" maxlength="50" />
          </template>
          <!-- 批号 -->
          <template slot-scope="{ row, index }" slot="batch_number">
            <Input placeholder="请输入" size="small" v-model="row.batch_number" @on-change="changePihao(row.batch_number, index)" style="width: 120px" type="tel" maxlength="50" />
          </template>
          <!-- 数量 -->
          <template slot-scope="{ row, index }" slot="quantity">
            <Input placeholder="请输入" size="small" v-model="row.quantity" @on-change="changeShuliang(row.quantity, index)" style="width: 120px" type="number" />
          </template>
          <!-- 有效期 -->
          <template slot-scope="{ row, index }" slot="valid_period">
            <DatePicker type="date" placeholder="请选择" style="width: 140px" v-model="row.valid_period" placement="left-start" format="yyyy-MM-dd" @on-change="changeDate(row.valid_period, index)"></DatePicker>
          </template>
        </Table>
      </div>
      <Row class="btn-group">
        <Col span="24" v-if="type === 2 && edit === 1">
          <Button class="btn" @click="outputStore" :disabled="disableB">转出库单</Button>
          <Button class="btn" @click="transferStatement" :disabled="disableA">转结算单</Button>
        </Col>
        <Col span="24" v-if="type === 3 && edit === 1">
          <Button class="btn" @click="resaleOutboundOrder" :disabled="disableC">转销售出库单</Button>
        </Col>
        <Col span="24" v-if="type === 4 && edit === 1">
          <Button class="btn" @click="transferOrder">转单</Button>
        </Col>
      </Row>
      <Modal v-model="deleteModel" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
        <div class="reviewDiv">
          <p>是否删除记录？</p>
          <div>
            <span class="pageBtn finger btnSure" @click="deleteAddItem">确定</span>
            <span class="pageBtn finger btnCancle" @click="deleteModel = false">取消</span>
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
export default {
  name: 'customerScaleDetailConsign',
  data() {
    return {
      // 总表表头
      listColumns: [
        {
          title: '序号',
          type: 'index',
          width: 75,
          align: 'center',
        },
        {
          title: '产品编号',
          key: 'product_id',
          align: 'center',
          width: 150,
        },
        {
          title: '产品名称',
          key: 'product_name',
          minWidth: 260,
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'product_model_code',
          width: 150,
          align: 'center',
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          width: 150,
          align: 'center',
        },
        {
          title: '供应商',
          key: 'supplier_name',
          minWidth: 260,
          align: 'center',
        },
        {
          title: '数量',
          key: 'quantity',
          width: 75,
          align: 'center',
        },
      ],
      /* 详情时-委托代销表头 */
      listColumns1: [
        {
          title: '序号',
          type: 'index',
          minWidth: 75,
          width: 75,
          align: 'center',
        },
        {
          title: '产品编号',
          key: 'product_id',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '产品名称',
          key: 'product_name',
          minWidth: 260,
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'product_model_code',
          minWidth: 150,
          align: 'center',
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          minWidth: 150,
          align: 'center',
        },
        {
          title: '用量',
          key: 'use_quantity',
          minWidth: 75,
          align: 'center',
        },
        {
          title: '采购单价',
          slot: 'purchase_unit_price' /* 采购单价插槽 */,
          align: 'center',
          minWidth: 140,
        },
        {
          title: '销售单价',
          slot: 'sale_unit_price' /* 销售单价插槽 */,
          align: 'center',
          minWidth: 140,
        },
        {
          title: '单位',
          render: (h, params) => {
            return h('span', params.row.unit)
          },
          align: 'center',
          minWidth: 75,
        },
      ],
      /* 详情时-自寄售表头 */
      listColumns2: [
        {
          title: '序号',
          type: 'index',
          minWidth: 75,
          width: 75,
          align: 'center',
        },
        {
          title: '产品编号',
          key: 'product_id',
          minWidth: 160,
          align: 'center',
        },
        {
          title: '产品名称',
          key: 'product_name',
          minWidth: 365,
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'product_model_code',
          minWidth: 160,
          align: 'center',
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          minWidth: 160,
          align: 'center',
        },
        {
          title: '用量',
          key: 'use_quantity',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '销售单价',
          slot: 'sale_unit_price' /* 销售单价插槽 */,
          align: 'center',
          minWidth: 140,
        },
        {
          title: '单位',
          render: (h, params) => {
            return h('span', params.row.unit)
          },
          align: 'center',
          minWidth: 75,
        },
      ],
      /* 转单时-供方寄售表头 */
      listColumns3: [
        {
          title: '序号',
          type: 'index',
          minWidth: 75,
          width: 75,
          align: 'center',
        },
        {
          title: '产品名称',
          key: 'product_name',
          minWidth: 260,
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'product_model_code',
          minWidth: 150,
          align: 'center',
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          minWidth: 150,
          align: 'center',
        },
        {
          title: '条码',
          slot: 'bar_code' /* 采购单价插槽 */,
          key: 'bar_code',
          align: 'center',
          minWidth: 140,
        },
        {
          title: '批号',
          slot: 'batch_number' /* 销售单价插槽 */,
          key: 'batch_number',
          align: 'center',
          minWidth: 140,
        },
        {
          title: '数量',
          slot: 'quantity' /* 销售单价插槽 */,
          align: 'center',
          minWidth: 140,
        },
        {
          title: '有效期',
          slot: 'valid_period' /* 销售单价插槽 */,
          align: 'center',
          minWidth: 140,
        },
        {
          title: '操作',
          render: (h, params) => {
            return h(
              'span',
              {
                class: 'tableFont',
                on: {
                  click: () => {
                    this.delete_id = params.row.customer_meter_line_id
                    this.deleteModel = true
                  },
                },
              },
              '删除'
            )
          },
          align: 'center',
          minWidth: 75,
        },
      ],
      listData: [],
      pages: {
        page: 1,
        rows: 10,
      },
      total: 28,
      deleteModel: false,
      customer_meter_id: '' /* 用量表id */,
      type: '' /* 进入的用量表类型 */,
      edit: '' /* 进入的状态 */,
      lineCode: '' /* 条形码数据 */,
      purchase_unit_price: '' /* 采购单价 */,
      sale_unit_price: '' /* 销售单价 */,
      delete_id: 0, // 要删除的明细id
      disableA: false, // 转结算单禁用
      disableB: false, // 转出库单
      disableC: false, // 转销售出库单
    }
  },
  methods: {
    /* 分页 */
    changePage(e) {
      this.pages.page = e
    },
    /* 获取用量表详情 */
    getDetail(id) {
      this.$http.get(this.$apiConsign.customerUsageDetail, { customer_meter_id: id }).then(res => {
        if (res.status) {
          let detailList = []
          for (const item of res.data) {
            let customer_meter_line_id = item.id
            let product_id = item.product_id
            let product_name = item.product_name
            let product_model_code = item.product_model_code
            let item_number = item.item_number
            let use_quantity = item.use_quantity // 用量
            let unit = item.unit
            let quantity = item.quantity // 数量
            let bar_code = item.bar_code // 条码
            let batch_number = item.batch_number // 批号
            let supplier_name = item.supplier_name
            let valid_period = item.valid_period ? this.$moment.unix(item.valid_period).format('YYYY-MM-DD') : ''
            let purchase_unit_price = item.purchase_unit_price
            let sale_unit_price = item.sale_unit_price
            let obj = { customer_meter_line_id, product_id, product_name, product_model_code, item_number, unit, use_quantity, valid_period, purchase_unit_price, sale_unit_price, quantity, batch_number, bar_code, supplier_name }
            detailList.push(obj)
          }
          if (res.transfer_order === '0') {
            this.disableA = true
          }
          if (res.transfer_order === '1') {
            this.disableB = true
          }
          if (res.meter_status === '1') {
            this.disableA = true
            this.disableB = true
          }
          this.listData = detailList
        }
      })
    },
    /* 返回上一页 */
    goBack() {
      let type = this.type
      this.$router.push({
        path: '/customerScaleConsign',
        query: {
          type,
        },
      })
    },
    /* 转出库单 */
    outputStore() {
      let line_info = []
      let index = 0
      let flag = true
      for (const item of this.listData) {
        index++
        let customer_meter_line_id = item.customer_meter_line_id
        let purchase_unit_price = 0
        let sale_unit_price = 0
        if (item.purchase_unit_price && item.sale_unit_price) {
          purchase_unit_price = item.purchase_unit_price
          sale_unit_price = item.sale_unit_price
          let info = { customer_meter_line_id, purchase_unit_price, sale_unit_price }
          line_info.push(info)
        } else {
          this.$Message.error('请检查第' + index + '项数据')
          flag = false
        }
      }
      if (flag) {
        let customer_meter_id = this.customer_meter_id
        let obj = { line_info, customer_meter_id }
        console.log(obj)
        this.disableB = true
        this.$http.put(this.$apiConsign.customerTransferStatement, obj, true).then(res => {
          if (res.status) {
            this.getDetail(customer_meter_id)
          }
        })
      }
    },
    /* 转结算单 */
    transferStatement() {
      let line_info = []
      let index = 0
      let flag = true
      for (const item of this.listData) {
        index++
        let customer_meter_line_id = item.customer_meter_line_id
        let purchase_unit_price = 0
        let sale_unit_price = 0
        if (item.purchase_unit_price && item.sale_unit_price) {
          purchase_unit_price = item.purchase_unit_price
          sale_unit_price = item.sale_unit_price
          let info = { customer_meter_line_id, purchase_unit_price, sale_unit_price }
          line_info.push(info)
        } else {
          this.$Message.error('请检查第' + index + '项数据')
          flag = false
        }
      }
      if (flag) {
        let customer_meter_id = this.customer_meter_id
        let obj = { line_info, customer_meter_id }
        console.log(obj)
        this.disableA = true
        this.$http.put(this.$apiConsign.customerTransferStatement, obj, true).then(res => {
          if (res.status) {
            this.getDetail(customer_meter_id)
          }
        })
      }
    },
    /* 转销售出库单 */
    resaleOutboundOrder() {
      let line_info = []
      let index = 0
      let flag = true
      for (const item of this.listData) {
        index++
        let customer_meter_line_id = item.customer_meter_line_id
        let sale_unit_price = 0
        if (item.sale_unit_price || item.sale_unit_price === 0) {
          sale_unit_price = item.sale_unit_price
          let info = { customer_meter_line_id, sale_unit_price }
          line_info.push(info)
          flag = true
        } else {
          this.$Message.error('请检查第' + index + '项数据')
          flag = false
        }
      }
      if (flag) {
        let customer_meter_id = this.customer_meter_id
        let obj = { line_info, customer_meter_id }
        console.log(obj)
        this.disableC = true
        this.$http.put(this.$apiConsign.resaleOutboundOrder, obj, true).then(res => {
          if (res.status) {
            this.getDetail(customer_meter_id)
          }
        })
      }
    },
    /* 改变采购单价 */
    changePurchasePrice(item, index) {
      let str = item + ''
      let minLength = 0
      let maxlength = 0
      if (str.indexOf('.') !== -1) {
        minLength = str.split('.')[1].length
        maxlength = str.split('.')[0].length
      } else {
        maxlength = str.length
      }
      if (Number(item) >= 0 && maxlength < 9 && minLength < 3) {
        this.listData[index].purchase_unit_price = Number(item)
      } else {
        this.$Message.error('请输入有效数字')
        this.listData[index].purchase_unit_price = null
      }
    },
    /* 改变销售单价 */
    changeSalePrice(item, index) {
      let str = item + ''
      let minLength = 0
      let maxlength = 0
      if (str.indexOf('.') !== -1) {
        minLength = str.split('.')[1].length
        maxlength = str.split('.')[0].length
      } else {
        maxlength = str.length
      }
      if (Number(item) >= 0 && maxlength < 9 && minLength < 3) {
        this.listData[index].sale_unit_price = Number(item)
      } else {
        this.$Message.error('请输入有效数字')
        this.listData[index].sale_unit_price = null
      }
    },
    /* 改变条码 */
    changeTiaoma(item, index) {
      this.$set(this.listData[index], 'bar_code', item)
    },
    /* 改变批号 */
    changePihao(item, index) {
      this.$set(this.listData[index], 'batch_number', item)
    },
    /* 改变数量 */
    changeShuliang(item, index) {
      /* let str = item + ''
      if (item > 0 && str.length < 9) {
        let str = item + ''
        let strIndex = str.indexOf('.')
        if (strIndex !== -1) {
          this.$Message.error('不能为小数')
          this.$set(this.listData[index], 'quantity', null)
        } else {
          this.$set(this.listData[index], 'quantity', item)
        }
      } else {
        this.$Message.error('请输入有效数字')
        this.$set(this.listData[index], 'quantity', null)
      } */
      let str = item + ''
      let strIndex = str.indexOf('.')
      let symbalIndex = str.indexOf('-')
      this.$set(this.listData[index], 'quantity', item)
      if (strIndex !== -1) {
        this.$Message.error('不能为小数')
        this.$set(this.listData[index], 'quantity', null)
      }
      if (symbalIndex !== -1) {
        this.$Message.error('不能为负数')
        this.$set(this.listData[index], 'quantity', null)
      }
      if (str.length > 8) {
        this.$Message.error('数字长度不超过8')
        this.$set(this.listData[index], 'quantity', null)
      }
    },
    /* 改变有效期 */
    changeDate(item, index) {
      if (item) {
        item = this.$moment(item).format('YYYY-MM-DD')
        this.$set(this.listData[index], 'valid_period', item)
      } else {
        this.$set(this.listData[index], 'valid_period', null)
      }
    },
    /* 删除项目 */
    deleteAddItem() {
      let meter_line_id = this.delete_id
      this.$http.DeleteAll(this.$apiConsign.customerUsageList, { meter_line_id }, true).then(res => {
        if (res.status) {
          this.getDetail(this.customer_meter_id)
          this.deleteModel = false
        }
      })
    },
    /* 转单 */
    transferOrder() {
      let line_info = []
      let flag = true // 判断数量和有效期的条件
      let index = 0
      for (const item of this.listData) {
        index++
        let customer_meter_line_id = item.customer_meter_line_id
        let bar_code = item.bar_code
        let batch_number = item.batch_number
        if (item.quantity && item.valid_period && item.batch_number) {
          // 数量非零和有效期填写时通过
          let quantity = item.quantity
          let valid_period = item.valid_period
          let obj = { customer_meter_line_id, bar_code, batch_number, quantity, valid_period }
          line_info.push(obj)
        } else {
          flag = false
          let str = '第' + index + '条产品'
          if (!item.quantity) {
            let str1 = '，数量填写有误'
            str = str + str1
          }
          if (!item.valid_period) {
            let str2 = '，有效期填写有误'
            str = str + str2
          }
          if (!item.batch_number) {
            let str3 = '，批号不能为空'
            str = str + str3
          }
          this.$Message.error(str)
        }
      }
      if (flag) {
        let customer_meter_id = this.customer_meter_id
        let putObj = { line_info, customer_meter_id }
        this.$http.put(this.$apiConsign.transferOrder, putObj, true).then(res => {
          if (res.status) {
            this.$router.push('/customerScaleConsign')
          }
        })
      }
    },
  },
  created() {
    /* 获取路由传来的用量表id */
    let customer_meter_id = this.$route.query.customer_meter_id
    this.customer_meter_id = customer_meter_id
    /* 获取路由传来的用量表类型 */
    let type = this.$route.query.type
    let edit = this.$route.query.edit
    this.type = type
    this.edit = edit
    /* 根据类型显示不同的表格 */
    if (edit) {
      switch (type) {
        /* 当类型为委托代销时，表头为委托代销的表头 */
        case 2:
          this.listColumns = this.listColumns1
          break
        /* 当类型为自寄售时，表头为自寄售的表头 */
        case 3:
          this.listColumns = this.listColumns2
          break
        // 当类型为供方寄售，表头为供方寄售-转单
        case 4:
          this.listColumns = this.listColumns3
          break
        // 总表表头-详情，无修改
        case 1:
          break
        default:
          break
      }
    }
    this.getDetail(customer_meter_id)
  },
}
</script>

<style lang="less" scoped>
.page {
  display: flex;
  flex-direction: column;
  .back {
    width: 76px;
    height: 36px;
    margin: 19px 23px;
    background: #f0f8ff;
    border-radius: 4px;
    border: 1px solid #389afc;
    color: #389afc;
  }
  .btn-group {
    position: absolute;
    bottom: 38px;
    right: 35px;
    .btn {
      width: 123px;
      height: 36px;
      margin-right: 20px;
      float: right;
      background: #f0f8ff;
      border-radius: 4px;
      border: 1px solid #389afc;
      color: #389afc;
    }
  }
  .tabelDiv {
    flex: 1;
    background: white;
    border-radius: 0 8px 8px 8px;
    padding: 20px 25px;
    /deep/ .ivu-table-wrapper {
      position: static !important;
    }
  }
  .addPage {
    display: flex;
    overflow: hidden;
    /deep/ .areaFlex1 {
      flex: 1;
      .ivu-input-wrapper {
        height: 100% !important;
      }
      .ivu-input {
        height: 100% !important;
      }
    }
    .title {
      height: 25px;
      font-size: 18px;
      font-weight: 500;
      color: #525b6d;
      line-height: 25px;
    }
    .leftDiv {
      width: 370px;
      height: 810px;
      border-right: 1px solid #e8eaec;
      padding: 27px 24px 26px 24px;
      display: flex;
      flex-direction: column;
      .leftTopDiv {
        margin-bottom: 21px;
        margin-top: 5px;
      }
    }
    .rightDiv {
      padding: 27px 24px 26px 24px;
      flex: 1;
      overflow-y: auto;
      .rightTopDiv {
        height: 36px;
        line-height: 36px;
        margin-bottom: 14px;
      }
      .marginTop14 {
        margin-top: 14px;
      }
    }
  }
}
</style>
